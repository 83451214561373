import _ from 'lodash';
import React from 'react';
import Highlighter from './Highlighter';
import RequestResponse from './RequestResponse';

import 'highlight.js/styles/monokai-sublime.css';
import '../styles/log-item.scss';
import { Col, Container, Row, Table } from 'react-bootstrap';

interface Props {
  data: {
    body: string;
    request: string;
    response: string;
  } | null;
}

const LogItem = ({ data }: Props) => {
  if (!data || !Object.keys(data).length) {
    return (
      <Row className="h-100">
        <Col sm="12" className="my-auto text-center">
          <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#666' }}>
            Nothing Selected
            <br />
            <i className="far fa-sad-tear" />
          </div>
        </Col>
      </Row>
    );
  }

  const rows: React.ReactNode[] = [];

  _.each(data, (val, key) => {
    val = _.trim(val);

    if (['body', 'request', 'response'].includes(key)) {
      return;
    }

    rows.push(
      <tr key={key}>
        <td>{key}</td>
        <td>
          <Highlighter data={val} />
        </td>
      </tr>
    );
  });

  return (
    <Container fluid className="log-item">
      <Table bordered className="log-item mt-3">
        <thead className="bg-secondary">
          <tr>
            <th>Key</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <RequestResponse request={data.body || data.request} response={data.response} />
    </Container>
  );
};

export default LogItem;
