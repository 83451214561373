import React from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import Auth from './services/Auth';
import App from './components/App';
import Home from './components/Home';
import Login from './components/Login';
import LogListPage from './components/LogListPage';
import LogItemPage from './components/LogItemPage';
import NavPage from './components/NavPage';
import Test from './components/Test';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        Auth.loggedIn() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

export default (
  <BrowserRouter>
    <RecoilRoot>
      <App>
        <NavPage>
          <PrivateRoute exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/test" component={Test} />
          <PrivateRoute exact path="/logs/:name" component={LogListPage} />
          <PrivateRoute exact path="/logs/:name/:id" component={LogItemPage} />
        </NavPage>
      </App>
    </RecoilRoot>
  </BrowserRouter>
);
