import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import Socket from '../services/Socket';
import Api from '../services/Api';
import LogItem from './LogItem';
import { selectedItemState } from '../store';

interface Props {
  name?: string;
  itemId?: string;
  paned?: boolean;
}

const LogItemContainer = ({ name = '', itemId = '', paned = false }: Props) => {
  const [ready, setReady] = useState(false);
  const [item, setItem] = useState<any>(null);
  const selectedItem = useRecoilValue<any>(selectedItemState);

  // ref used for sub update events
  const itemRef = useRef(null);
  itemRef.current = item;

  useEffect(() => {
    setItem(selectedItem);
  }, [selectedItem]);

  const subscribe = () => {
    Socket.logs.emit('subscribe', name);
    Socket.logs.on(`${name}/${itemId}/update`, (res: any) => {
      setItem({ ...(itemRef.current || {}), ...res.data });
    });
  };

  const unsubscribe = () => {
    Socket.logs.off(`${name}/${itemId}/update`);
    Socket.logs.emit('unsubscribe', name);
  };

  const getData = () => {
    return Api.get(`logs/${name}/${itemId}`).then((res) => {
      setItem(res.data);
      setReady(true);
    });
  };

  useEffect(() => {
    if (paned) {
      setReady(true);
    } else {
      subscribe();
      getData();
    }

    return () => {
      if (!paned) {
        unsubscribe();
      }
    };
  }, []);

  if (!ready) {
    return <div>Loading...</div>;
  }

  return <LogItem data={item} />;
};

export default LogItemContainer;
