import React from 'react';
import SplitPane from 'react-split-pane';
import { RouteComponentProps } from 'react-router';

import LogItemContainer from './LogItemContainer';
import LogList from './LogList';

const LUMBERJACK_SPLIT_POS_KEY = 'lumberjack2.splitPos';
interface MatchParams {
  name: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

const LogListPage = ({ match }: Props) => {
  const { name } = match.params;

  return (
    <SplitPane
      split="horizontal"
      defaultSize={localStorage.getItem(LUMBERJACK_SPLIT_POS_KEY) || '60%'}
      onChange={(size) => localStorage.setItem(LUMBERJACK_SPLIT_POS_KEY, String(size + 'px'))}
    >
      <LogList name={name} />
      <LogItemContainer paned />
    </SplitPane>
  );
};

export default LogListPage;
