import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { AgGridColumnProps, AgGridReact } from '@ag-grid-community/react';
import moment from 'moment-timezone';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';

import {
  AllCommunityModules,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IDatasource,
  RowClickedEvent,
  RowNode,
} from '@ag-grid-community/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-balham.css';
import '@ag-grid-community/core/dist/styles/ag-theme-balham-dark.css';

import { searchQueryState, selectedItemState } from '../store';
import Api from '../services/Api';
import Socket from '../services/Socket';
import ListRowToolbar from './ListRowToolbar';

import '../styles/log-list.scss';

interface Props {
  name: string;
}

const icons = {
  menu: '<i class="fas fa-ellipsis-v"></i>',
  sortAscending: '<i class="fas fa-sort-up"></i>',
  sortDescending: '<i class="fas fa-sort-down"></i>',
  menuCopy: '<i class="fas fa-copy"></i>',
};

const LogList = ({ name }: Props) => {
  const gridApi = useRef<GridApi | null>(null);
  const columnApi = useRef<ColumnApi | null>(null);
  const [gridReady, setGridReady] = useState(false);

  const searchQuery = useRecoilValue(searchQueryState);
  const [selectedItem, setSelectedItem] = useRecoilState<any>(selectedItemState);

  const getSelectedItem = useRecoilCallback<any, any>(({ snapshot }) => () => {
    return snapshot.getLoadable(selectedItemState).contents;
  });

  const getSearchQuery = useRecoilCallback<any, any>(({ snapshot }) => () => {
    return snapshot.getLoadable(searchQueryState).contents;
  });

  const options = useRef<any>({
    searchQuery,
    sort: null,
    limit: 100,
    from: 0,
    filter: undefined,
  });

  const timers = useRef<any[]>([]);

  useEffect(() => {
    const handleResize = () => {
      if (gridApi.current) {
        gridApi.current?.sizeColumnsToFit();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      for (const timer of timers.current) {
        clearInterval(timer);
      }
    };
  }, []);

  // hack to get the correct value in AgGrid dataSource
  options.current.searchQuery = searchQuery;

  const dataSource: IDatasource = {
    rowCount: undefined,
    getRows: (params) => {
      options.current = {
        ...options.current,
        sort: params.sortModel,
        limit: params.endRow - params.startRow,
        from: params.startRow,
        filter: params.filterModel,
      };

      getData().then((res) => {
        const { docs, total } = res.data;

        if (!docs) {
          params.successCallback([], -1);
          return;
        }

        const lastRow = total <= params.endRow ? total : -1;

        if (docs.length && docs[0].createdAt) {
          params.successCallback(
            docs.map((doc: any) => {
              doc.createdAt = moment.tz(doc.createdAt, moment.tz.guess()).format('YYYY-MM-DD h:mm:ss a zz');
              return doc;
            }),
            lastRow
          );
        } else {
          params.successCallback(docs, lastRow);
        }
      });
    },
  };

  useEffect(() => {
    if (gridReady) {
      debouncePurgeInfiniteCache();
    }
  }, [searchQuery]);

  const onSelectionChanged = () => {
    if (gridApi.current) {
      const selectedRows = gridApi.current.getSelectedRows();
      if (selectedRows.length) {
        setSelectedItem(selectedRows[0]);
      }
    }
  };

  const onColumnResized = (ev: any) => {
    // error happens after 2nd resize
    // TODO FIX
    // if (ev.source !== 'sizeColumnsToFit' && ev.column) {
    //   const colDefs = ev.api.getColumnDefs();
    //   for (const colDef of colDefs) {
    //     if ('colId' in colDef) {
    //       if (colDef.colId === ev.column.getColId()) {
    //         colDef.suppressSizeToFit = true;
    //         break;
    //       }
    //     }
    //   }
    //   ev.api.setColumnDefs(colDefs);
    // }
  };

  const handleRowClick = (rowNode: RowClickedEvent) => {
    // selectedItem and clicked item are the same
    if (selectedItem && selectedItem?._id === rowNode.node.data._id) {
      return;
    }

    setSelectedItem(rowNode.node.data);
  };

  const debounceOnColumnResized = _.debounce(onColumnResized, 500);

  const gridOptions: GridOptions = {
    // enableServerSideSorting: true,
    // enableServerSideFilter: true,
    rowModelType: 'infinite',
    cacheOverflowSize: 10,
    cacheBlockSize: 100,
    maxConcurrentDatasourceRequests: 2,
    infiniteInitialRowCount: 1,
    maxBlocksInCache: 10,
    rowSelection: 'single',
    getRowNodeId: (item: any) => item._id,
    datasource: dataSource,
    defaultColDef: {
      filter: true,
      sortable: true,
      resizable: true,
    },
    onSelectionChanged,
    // onColumnResized: debounceOnColumnResized,
  };

  const refreshPageCache = () => {
    if (gridApi.current) {
      // gridApi.current.purgeInfiniteCache();
      gridApi.current.refreshInfiniteCache();
      setTimeout(() => {
        if (gridApi.current) {
          gridApi.current.sizeColumnsToFit();
        }
      }, 100);
    }
  };

  const purgeInfiniteCache = () => {
    if (gridApi.current) {
      gridApi.current.purgeInfiniteCache();
    }
  };

  const debounceRefreshPageCache = _.debounce(refreshPageCache, 1000);
  const debouncePurgeInfiniteCache = _.debounce(purgeInfiniteCache, 1000);

  const subscribe = () => {
    Socket.logs.emit('subscribe', name);
    Socket.logs.on(`${name}/new`, () => {
      if (gridApi.current) {
        // const maxRowFound = gridApi.current.isMaxRowFound();
        // if (maxRowFound) {
        //   const rowCount = gridApi.current.getInfiniteRowCount();
        //   gridApi.current.setInfiniteRowCount(rowCount + 1);
        // }
      }

      // https://www.ag-grid.com/javascript-grid-infinite-scrolling/#gsc.tab=0
      // inserting items with infinite scrolling can have issues
      debounceRefreshPageCache();
    });

    Socket.logs.on(`${name}/update`, (res: any) => {
      updateRowById(res.data, res.id);
    });

    Socket.logs.on(`${name}/delete`, () => {
      debounceRefreshPageCache();
    });
  };

  const unsubscribe = () => {
    ['new', 'update', 'delete'].forEach((action) => {
      Socket.logs.off(`${name}/${action}`);
    });

    Socket.logs.emit('unsubscribe', name);
  };

  useEffect(() => {
    subscribe();
    return () => {
      unsubscribe();
    };
  }, []);

  const findRowById = (id: number): RowNode | null => {
    let foundNode = null;
    if (!gridApi.current) {
      return null;
    }

    gridApi.current.forEachNode((node) => {
      if (_.get(node, 'data._id') === id) {
        foundNode = node;
      }
    });

    return foundNode;
  };

  const updateRowById = (data: any, id: number) => {
    const selectedItem = getSelectedItem();

    const node = findRowById(id);
    if (!node || !node.data) {
      return;
    }

    node.data = { ...node.data, ...data };

    if (selectedItem && selectedItem._id === id) {
      setSelectedItem({ ...node.data, _id: id });
    }

    if (gridApi.current) {
      gridApi.current.refreshCells({ rowNodes: [node] });
    }
  };

  const deleteRowById = (id: number) => {
    const node = findRowById(id);
    if (gridApi.current && node) {
      gridApi.current.removeItems([node]);
    }
  };

  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
    columnApi.current = params.columnApi;
    setGridReady(true);
    getData().then((res: any) => {
      gridApi.current?.setColumnDefs(getColumnDefs(res.data.mapping));
      gridApi.current?.sizeColumnsToFit();
      // params.api.setDatasource(dataSource);
    });

    // timers.current.push(
    //   setInterval(() => {
    //     if (gridApi.current) {
    //       gridApi.current.sizeColumnsToFit();
    //     }
    //   }, 1000)
    // );
  };

  const getData = () => {
    return Api.get(`/logs/${name}`, {
      params: {
        q: getSearchQuery(),
        from: options.current.from,
        limit: options.current.limit,
        agSort: options.current.sort ? btoa(JSON.stringify(options.current.sort)) : '',
        agFilter: options.current.filter ? btoa(JSON.stringify(options.current.filter)) : '',
      },
    });
  };

  const getColumnDefs = (mapping: any) => {
    // preserve sorting on re-render
    let sortModel: any = mapping.createdAt
      ? {
          createdAt: {
            sort: 'desc',
          },
        }
      : {};

    if (gridApi.current) {
      sortModel = _.keyBy(gridApi.current.getSortModel(), 'colId');
    }

    const columnDefs: AgGridColumnProps[] = [
      {
        colId: '_id',
        hide: true,
      },
    ];

    columnDefs.unshift({
      width: 45,
      pinned: 'left',
      headerName: '',
      field: 'action',
      suppressSizeToFit: true,
      suppressMenu: true,
      sortable: false,
      cellStyle: { textAlign: 'center' },
      cellRendererFramework: (cellProps: ICellRendererParams) => {
        if (cellProps.data === undefined) {
          return <i className="fas fa-sync fa-spin" />;
        }

        return <ListRowToolbar itemId={cellProps.data._id} logName={name} />;
      },
    });

    for (const colName of Object.keys(mapping)) {
      const type = mapping[colName].type;
      let agType = 'agTextColumnFilter';
      let cellRenderer;

      // ES types to AG types
      // https://www.elastic.co/guide/en/elasticsearch/reference/current/mapping-types.html
      // https://www.ag-grid.com/javascript-grid-filtering/#gsc.tab=0
      switch (type) {
        case 'long':
        case 'integer':
        case 'short':
        case 'byte':
        case 'double':
        case 'float':
          agType = 'number';
          break;

        case 'date':
          agType = 'date';
          break;

        case 'keyword':
        case 'text':
        case 'string':
          cellRenderer = (params: ICellRendererParams) => _.escape(params.value);
      }

      let colDef: AgGridColumnProps = {
        headerName: colName,
        field: colName,
        filter: agType,
        cellRenderer,
        sortable: true,
        resizable: true,
      };

      if (sortModel[colName]) {
        colDef = _.merge(colDef, sortModel[colName]);
      }

      columnDefs.push(colDef);
    }

    return columnDefs;
  };

  const agGridTheme = localStorage.getItem('theme') === 'darkly' ? 'ag-theme-balham-dark' : 'ag-theme-balham';

  return (
    <div id="list-container" className="content-container">
      <div id="grid-wrapper" className={agGridTheme}>
        <AgGridReact
          modules={AllCommunityModules}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          onRowClicked={handleRowClick}
          icons={icons}
          suppressCellSelection
        />
      </div>
    </div>
  );
};

export default LogList;
