import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

interface Props {
  logName: string;
  itemId: string;
}

const ListRowToolbar = ({ logName, itemId }: Props) => {
  const history = useHistory();

  const handleClick = (e: React.MouseEvent<Element>, url: string) => {
    e.preventDefault();
    if (e.ctrlKey) {
      window.open(url);
    } else {
      history.push(url);
    }
  };

  const renderViewButton = () => {
    const url = `/logs/${logName}/${itemId}`;

    return (
      <Button
        variant="primary"
        size="sm"
        href={url}
        className="list-edit-button"
        title="View Detail"
        onClick={(e: React.MouseEvent<Element>) => {
          handleClick(e, url);
        }}
      >
        <i className="fas fa-search-plus" />
      </Button>
    );
  };

  return <div>{renderViewButton()}</div>;
};

export default ListRowToolbar;
