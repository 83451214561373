import _ from 'lodash';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useSetRecoilState } from 'recoil';
import { searchQueryState } from '../store';

const MainSearch = () => {
  const setSearchQueryState = useSetRecoilState(searchQueryState);

  const updateSearchQuery = _.debounce((value) => {
    setSearchQueryState(value);
  }, 200);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    updateSearchQuery(e.target.value);
  };

  return (
    <Form.Control
      style={{ width: 'inherit' }}
      className="d-inline-block"
      type="text"
      placeholder="Search"
      onChange={handleChange}
    />
  );
};

export default MainSearch;
