import _ from 'lodash';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import BreadCrumbs from './BreadCrumbs';
import LogItemContainer from './LogItemContainer';

interface MatchParams {
  name: string;
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

const LogItemPage = ({ match }: Props) => {
  const { name, id } = match.params;

  return (
    <div>
      <BreadCrumbs list={[{ url: `/logs/${name}`, label: _.capitalize(name) }]} />
      <LogItemContainer name={name} itemId={id} />
    </div>
  );
};

export default LogItemPage;
