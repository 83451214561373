import React, { useEffect, useState } from 'react';
import Api from '../services/Api';
import { useSetRecoilState } from 'recoil';
import { userState } from '../store';

const App = ({ children }: { children: React.ReactNode }) => {
  const [ready, setReady] = useState(false);
  const setUserState = useSetRecoilState<any>(userState);

  useEffect(() => {
    Api.get('user')
      .then(({ data }) => {
        setUserState(data.user);
        setReady(true);
      })
      .catch((err) => {
        console.error(err);
        setReady(true);
      });
  }, []);

  if (!ready) {
    return null;
  }

  return <div id="app">{children}</div>;
};

export default App;
