import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

interface BreadCrumb {
  url: string;
  label: string;
}

interface Props {
  list: BreadCrumb[];
}

const BreadCrumbs = ({ list = [] }: Props) => {
  const history = useHistory();

  const items = list.map((item, i) => {
    return (
      <Breadcrumb.Item key={i}>
        <a
          href={item.url}
          onClick={(e) => {
            e.preventDefault();
            history.push(item.url);
          }}
        >
          {item.label}
        </a>
      </Breadcrumb.Item>
    );
  });

  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            history.push('/');
          }}
        >
          Home
        </a>
      </Breadcrumb.Item>
      {items}
    </Breadcrumb>
  );
};

export default BreadCrumbs;
