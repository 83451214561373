import React from 'react';
import Navbar from './Navbar';

interface Props {
  children: React.ReactNode;
}

const NavPage = ({ children }: Props) => {
  return (
    <div id="nav-page">
      <Navbar />
      {children}
    </div>
  );
};

export default NavPage;
