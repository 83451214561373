import axios from 'axios';
import Auth from './Auth';
import config from '../config';

const client = axios.create({
  baseURL: config.apiUrl,
  validateStatus: () => true,
  headers: {},
});

client.interceptors.request.use((config) => {
  config.headers.authorization = Auth.getToken();
  return config;
});

export default client;
