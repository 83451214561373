import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Table } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import Api from '../services/Api';
import { searchQueryState } from '../store';

const Test = () => {
  const [docs, setDocs] = useState([]);
  const [logName, setLogName] = useState('');
  const searchQuery = useRecoilValue(searchQueryState);

  useEffect(() => {
    Api.get(`/logs/${logName}`, {
      params: {
        q: searchQuery,
      },
    }).then((res) => {
      setDocs(res.data.docs);
    });
  }, []);

  const postLogData = () => {
    if (!logName) {
      return;
    }

    Api.post(`logs/${logName}`, {
      name: 'fake',
      rand: Math.random().toString(36).substring(7),
      rand2: Math.random().toString(36).substring(7),
    });
  };

  const renderRow = (doc: any) => {
    return (
      <tr>
        {Object.values(doc).map((v: any) => (
          <td>{v}</td>
        ))}
      </tr>
    );
  };

  return (
    <Container fluid>
      <Form.Group>
        <Form.Label>Log Name</Form.Label>
        <Form.Control onChange={(e) => setLogName(e.target.value)} />
      </Form.Group>
      <Button type="submit" onClick={() => postLogData()}>
        Submit
      </Button>
      <Table bordered>
        <tbody>{!!docs && !!docs.length && docs.map((doc) => renderRow(doc))}</tbody>
      </Table>
    </Container>
  );
};

export default Test;
