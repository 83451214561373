import { pd } from 'pretty-data';
import Highlight from 'react-highlight';

interface Props {
  data: string;
}

const isValidJson = (json: string) => {
  try {
    JSON.parse(json);
    return true;
  } catch (err) {
    return false;
  }
};

const Highlighter = ({ data }: Props) => {
  if (data.startsWith('<') && data.endsWith('>')) {
    return <Highlight className="xml">{pd.xml(data)}</Highlight>;
  } else if (data.startsWith('{') && data.endsWith('}') && isValidJson(data)) {
    return <Highlight className="json">{pd.json(data)}</Highlight>;
  } else if (data.startsWith('sXml=')) {
    return <Highlight className="xml">{pd.xml(data)}</Highlight>;
  }

  return <div>{data}</div>;
};

export default Highlighter;
