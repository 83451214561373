import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Api from '../services/Api';

const Home = () => {
  const [logs, setLogs] = useState([]);
  const history = useHistory();

  useEffect(() => {
    Api.get('logs').then((res) => {
      setLogs(res.data.logs);
    });
  }, []);

  // TODO render more than just the name
  return (
    <Container fluid>
      {logs &&
        logs.map((log, i) => {
          return (
            <Button
              variant="light"
              size="lg"
              block
              className="mt-3 text-left"
              key={i}
              onClick={() => history.push(`/logs/${log}`)}
            >
              <i className="fas fa-database mr-2" />
              {log}
            </Button>
          );
        })}
    </Container>
  );
};

export default Home;
