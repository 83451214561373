import { atom } from 'recoil';

export const searchQueryState = atom({
  key: 'searchQuery',
  default: '',
});

export const modalOpenState = atom({
  key: 'modalOpen',
  default: false,
});

export const selectedItemState = atom({
  key: 'selectedItem',
  default: null,
});

export const userState = atom({
  key: 'user',
  default: null,
});

export const themeState = atom({
  key: 'theme',
  default: 'flatly',
});
