import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Highlighter from './Highlighter';
import xmlFormat from 'xml-formatter';
import { Button, Card, Row, Col } from 'react-bootstrap';
interface Props {
  request: string;
  response: string;
}

const cleanData = (data: string = '') => {
  if (data.startsWith('<') && data.endsWith('>')) {
    data = data.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  } else if (data.startsWith('sXml=')) {
    // WHI crappy payload
    data = data
      .substring(5)
      .replace(/%3c/g, '<')
      .replace(/%3f/g, '?')
      .replace(/%3d/g, '=')
      .replace(/%22/g, "'")
      .replace(/%3e/g, '>')
      .replace(/%2f/g, '/')
      .replace(/%0a/g, '\n')
      .replace(/\+(?=([^"\\]*(\\.|"([^"\\]*\\.)*[^"\\]*"))*[^"]*$)/g, ' '); // match plus not in quotes
  }

  return data.trim();
};

const formatData = (data: string) => {
  if (data.startsWith('<') && data.endsWith('>')) {
    try {
      data = xmlFormat(data);
    } catch (err) {
      console.error(err);
    }
  } else if (data.startsWith('{') && data.endsWith('}')) {
    try {
      data = JSON.stringify(JSON.parse(data), null, 2);
    } catch (err) {
      console.error(err);
    }
  }

  return data;
};

const RequestResponse = ({ request, response }: Props) => {
  const [reqCopied, setReqCopied] = useState(false);
  const [resCopied, setResCopied] = useState(false);

  useEffect(() => {
    setReqCopied(false);
    setResCopied(false);
  }, [request, response]);

  const cleanRequest = cleanData(request);
  const cleanResponse = cleanData(response);

  return (
    <Row className="reqres" key={`logitem-reqres-${Date.now()}`}>
      <Col md="6">
        {!!cleanRequest && (
          <Card>
            <Card.Header>
              Request
              <span className="float-right">
                <CopyToClipboard text={formatData(cleanRequest)} onCopy={() => setReqCopied(true)}>
                  <Button size="sm" variant="primary">
                    {reqCopied ? 'Copied!' : 'Copy'}
                  </Button>
                </CopyToClipboard>
              </span>
            </Card.Header>
            <Card.Body>
              <Highlighter data={cleanRequest} />
            </Card.Body>
          </Card>
        )}
      </Col>
      <Col md="6">
        {!!cleanResponse && (
          <Card>
            <Card.Header>
              Response
              <span className="float-right">
                <CopyToClipboard text={formatData(cleanResponse)} onCopy={() => setResCopied(true)}>
                  <Button size="sm" variant="primary">
                    {resCopied ? 'Copied!' : 'Copy'}
                  </Button>
                </CopyToClipboard>
              </span>
            </Card.Header>
            <Card.Body>
              <Highlighter data={cleanResponse} />
            </Card.Body>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default RequestResponse;
