import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import GoogleLogin from 'react-google-login';
import { userState } from '../store';
import Auth from '../services/Auth';
import { useHistory } from 'react-router-dom';
import { Alert, Card, Col, Container, Row } from 'react-bootstrap';

const Login = () => {
  const [error, setError] = useState('');
  const history = useHistory();

  const setUserState = useSetRecoilState<any>(userState);

  const handleGoogleResponse = (response: any) => {
    if (response.error) {
      console.error(response.error);
      setError(response.error);
      return;
    }

    if (response.tokenId) {
      Auth.googleLogin(response.tokenId)
        .then(({ user }) => {
          setUserState(user);
          history.push('/');
        })
        .catch(console.error);
    }
  };

  const renderError = () => {
    if (!error) {
      return null;
    }

    return (
      <Alert variant="danger" className="mt-2">
        {error}
      </Alert>
    );
  };

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col md="4" sm="12">
          <Card className="mt-5">
            <Card.Body className="text-center">
              <GoogleLogin
                clientId="933105496273-bfr4r586hk87dfalr4cvueuh7fvvknj8.apps.googleusercontent.com"
                buttonText="Sign in with Google"
                onSuccess={handleGoogleResponse}
                onFailure={handleGoogleResponse}
                cookiePolicy={'single_host_origin'}
                hostedDomain="dsisolutions.biz"
                theme="dark"
              />
            </Card.Body>
          </Card>

          {renderError()}
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
