import Api from './Api';

const LUMBERJACK_TOKEN_KEY = 'lumberjack2.token';
class Auth {
  removeToken() {
    localStorage.removeItem(LUMBERJACK_TOKEN_KEY);
  }

  getToken() {
    return localStorage.getItem(LUMBERJACK_TOKEN_KEY);
  }

  loggedIn() {
    if (process.env.NODE_ENV === 'testing') {
      return true;
    }

    return !!this.getToken();
  }

  login(username, password) {
    const payload = {
      username,
      password,
    };

    return new Promise((resolve) => {
      Api.post('/login', payload).then((response) => {
        const { data, status } = response;

        if (status === 200) {
          localStorage.setItem(LUMBERJACK_TOKEN_KEY, data.token);
          resolve({ error: false, user: data.user });
        } else {
          resolve({ error: 'Username or password mismatch', user: {} });
        }
      });
    });
  }

  googleLogin(token) {
    return Api.post('/login/google', { token }).then((response) => {
      const { data } = response;

      localStorage.setItem(LUMBERJACK_TOKEN_KEY, data.token);
      return { error: false, user: data.user };
    });
  }
}

export default new Auth();
