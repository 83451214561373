import config from '../config';
import io from 'socket.io-client';

const socket = io(config.serverUrl, {
  transports: ['websocket'],
});

const logs = io(`${config.serverUrl}/logs`, {
  transports: ['websocket'],
});

export default {
  socket,
  logs,
};
