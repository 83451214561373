/* global window */
const config = {
  serverUrl: '',
  apiUrl: '',
};

if (window.location.hostname.startsWith('dev02') || process.env.NODE_ENV !== 'production') {
  config.serverUrl = `${window.location.protocol}//${window.location.hostname}:1338`;
} else {
  config.serverUrl = `${window.location.protocol}//${window.location.hostname}`;
}

config.apiUrl = `${config.serverUrl}/api`;

export default config;
