import React, { useLayoutEffect, useRef } from 'react';
import { Button, NavDropdown, Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Auth from '../services/Auth';
import { userState } from '../store';
import MainSearch from './MainSearch';

const setTheme = (theme: string) => {
  document.getElementsByTagName('html')[0].className = theme;
  const gridWrapper = document.getElementById('grid-wrapper');
  if (gridWrapper) {
    gridWrapper.className = theme == 'darkly' ? 'ag-theme-balham-dark' : 'ag-theme-balham';
  }
};

const MainNavbar = () => {
  const theme = useRef('flatly');
  const user = useRecoilValue<any>(userState);

  const toggleTheme = () => {
    theme.current = theme.current === 'flatly' ? 'darkly' : 'flatly';
    localStorage.setItem('lumberjack2.theme', theme.current);
    setTheme(theme.current);
  };

  useLayoutEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      theme.current = storedTheme;
      setTheme(storedTheme);
    }
  }, []);

  const handleLogout = () => {
    Auth.removeToken();
    window.location.href = '/';
  };

  const renderUserButton = () => {
    if (!user) {
      return null;
    }

    const getTitle = () => {
      return (
        <>
          {!!user.picture && <img src={user.picture} width="30" height="30" className="mr-1 rounded" />}
          {user.name}
        </>
      );
    };

    return (
      <NavDropdown id="user-dropdown" className="d-inline" title={getTitle()}>
        <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
      </NavDropdown>
    );
  };

  return (
    <Navbar style={{ zIndex: 2 }} bg="dark" variant="dark" className="justify-content-between">
      <Nav className="d-inline-flex align-items-center">
        <Link to="/">
          <Navbar.Brand className="d-inline-flex align-items-center">
            <div className="d-inline-block bg-secondary rounded mr-2">
              <img src="/logo.png" width="36" height="36" className="d-inline-block align-top" alt="" />
            </div>
            <div className="d-inline-block">Lumberjack</div>
          </Navbar.Brand>
        </Link>
        {!!user && <MainSearch />}
      </Nav>

      <Nav>
        <Button className="m-1" onClick={toggleTheme} title="Light/Dark Mode">
          <i className="fas fa-adjust" />
        </Button>
        {renderUserButton()}
      </Nav>
    </Navbar>
  );
};

export default MainNavbar;
